import React from "react";
import { Icon } from "@washingtonpost/wpds-ui-kit";
import TooledWashingtonPost from "@washingtonpost/wpds-assets/asset/tooled-washington-post";

/**
 * Democracy Dies in Darkness
 */
const Masthead = () => {
  const titleId = "masthead";

  return (
    <Icon
      className="masthead_svg__wplogo"
      label="Logo of The Washington Post"
      titleId={titleId}
      style={{ width: "400px", height: "75px" }}
    >
      <TooledWashingtonPost />
    </Icon>
  );
};

export default Masthead;
