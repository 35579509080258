import PropTypes from "@arc-fusion/prop-types";
import React, { Fragment } from "react";
import { styled, theme } from "@washingtonpost/wpds-ui-kit";
import FusionZeus from "../zeus/default";
import Masthead from "./_children/masthead";
import { HOMEPAGE_URL } from "../../../layouts/homepage.helpers";

// Because of a Zeus quirk, we have to set the width of the ad.
const TiffanyAd = ({ tile }) => {
  const adType = `tiffany_tile${tile === 2 ? "" : "_2"}`;
  const customFields = {
    adType
  };
  return (
    <span style={{ width: "184px", height: "90px" }}>
      <FusionZeus customFields={customFields} />
    </span>
  );
};

TiffanyAd.propTypes = {
  tile: PropTypes.number
};

const Tagline = () => {
  return (
    <div
      className="flex flex-1 items-center w-100 justify-center h-sm"
      style={{ marginTop: "-1px" }}
    >
      <div
        className="flex dn-hp-sm-to-xs items-center justify-center italic font-xxs lh-sm font-family-georgia gray-dark relative dib bg-white"
        style={{ padding: "0 20px" }}
      >
        Democracy Dies in Darkness
      </div>
    </div>
  );
};

const StyledSpacer = styled("div", {
  paddingTop: theme.space["200"],
  paddingBottom: theme.space["200"],
  backgroundColor: theme.colors.background,
  zIndex: 1,
  position: "relative"
});

/**
 * Return a component that is basically WaPo's masthead.
 *
 * @returns Masthead component.
 */
const HomepageMasthead = () => {
  return (
    <Fragment>
      <StyledSpacer className="db dn-hp-md-to-xs overflow-hidden">
        <div className="hpgrid-max-width ma-auto flex flex-1 items-center w-100">
          <TiffanyAd />
          <div className="flex flex-column flex-1">
            <a
              href={`${HOMEPAGE_URL}/?itid=masthead`}
              className="hp-masthead flex dn-hp-sm-to-xs justify-center w-100"
            >
              <Masthead />
            </a>
            <Tagline />
          </div>
          <TiffanyAd tile={2} />
        </div>
      </StyledSpacer>
    </Fragment>
  );
};

export default HomepageMasthead;
